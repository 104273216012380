@import "../variables";
.nav-tabs{
  border-bottom: 1px solid transparent;
  .nav-item{
    z-index: 10;
  }
  .nav-link {
    color: $primary-color;
    margin-bottom: -2px;
    border: 1px solid transparent;

    font-family: $font-alt;
    font-weight: 300;
    letter-spacing: 0.05em;
    font-size: 1.2em;

    &:hover,
    &:focus {
      color: $primary-color;
      border-color: $dark-btn-background $dark-btn-background $separator-color $dark-btn-background;
    }

    &.active {

      color: $theme-color-1;
      background-color: $foreground-color;
      border-color: $separator-color $separator-color $foreground-color $separator-color;
    }

  }

}

.tab-content {
  .card, .card-body, .card-header {
    border-top-left-radius: 0 !important;
  }
}