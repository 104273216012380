@import "../variables";

.MuiPaper-root{
  margin-top: 20px;
  border: 1px solid var($--theme-border-custom-color) !important;
}

.light{
  .MuiPaper-root{
    background-color: $--theme-secondary-bg-color !important;
  }
}
.dark{
  .MuiPaper-root,{
    background-color: var($--theme-secondary-bg-color) !important;
    color: var($--theme-text-color) !important;
  }
  .MuiTableRow-hover:hover td{
    background-color: var($--theme-separator-color);
    transition: background-color 0.15s linear;
  }

  .MuiInputBase-root fieldset{
    border-color: $border-color-custom !important;
  }

  .MuiInputBase-root, .MuiList-root{
    .MuiSvgIcon-root{
      color: $primary !important;
    }
  }
  .MuiCheckbox-root{
    color: $primary !important;
  }
}

// Sorted selected
.Mui-active, .Mui-active svg{
  color: $primary !important;
}

// Rows per page Footer
.MuiTablePagination-selectLabel{
  padding-top: 15px;
  color: $primary-color;
}

.MuiPaper-elevation2{
  box-shadow: none !important;
}
.MuiInputBase-root, .MuiTableCell-body{
  color: var($--theme-text-color) !important;
}

.MuiTypography-caption {
  color: var($--theme-secondary-text-color) !important;
}


div[class*='Component-horizontalScrollContainer'] {
  padding: 5px 15px;
}

.MuiIconButton-root, .MuiSelect-icon{
  color: $primary !important;
  &.Mui-disabled{
    color: var($--theme-secondary-text-color) !important;
  }
}

div:has(button.MuiIconButton-root){
  justify-content: center;
}
button.MuiIconButton-root{
  font-size: 1.25em !important;
}

.MuiInputBase-input{
  padding: 10px 14px 10px 0 !important;
}

.MuiSelect-select{
  padding: 10px 14px !important;
}

.MuiTableCell-root {
  position: relative;
  font-weight: 300 !important;
  font-size: 0.9em !important;
  border-bottom: 1px solid $border-color-custom !important;
}

#m--table--filter--row.MuiTableRow-root{
  .MuiTableCell-root{
    padding: 10px 15px 10px;
    //border-bottom: none !important;
    border-style: dashed !important;
  }
}

.MuiTableHead-root {
  border-top: 1px solid $border-color-custom !important;
}

.MuiTableCell-head {
  color: var($--theme-text-color) !important;
  font-family: $font-alt !important;
  font-size: 1.1rem !important;
  background-color: transparent !important;
  z-index: 3 !important;
  padding: 5px 16px !important;

  &[style*="position: sticky;"]{
    background-color: $body-bg !important ;
  }
}

.MuiTableSortLabel-root
{
  &:hover {color: var($--theme-muted-text-color) !important;}
}

.MuiTableSortLabel-root.MuiTableSortLabel-active,
.MuiTableSortLabel-root.MuiTableSortLabel-active.MuiTableSortLabel-root.MuiTableSortLabel-active .MuiTableSortLabel-icon
{
  color: $primary !important;
}

.MuiInput-underline {
  &:before {
    border-bottom: 2px solid $border-color-custom !important;
  }
  &:after {
    border-bottom: 2px solid $border-color-custom !important;
  }
}

.MuiCircularProgress-colorPrimary {
  color: var($--theme-text-color) !important;
}
.MuiLinearProgress-colorPrimary {
  background-color: var($--theme-text-color) !important;
}
.MuiLinearProgress-barColorPrimary {
  background-color: $primary !important;
}

.MuiIconButton-root{
  .MuiIconButton-label > i {
    font-size: 0.75em;
  }
}

.card .MuiPaper-root, .card-bg .MuiPaper-root{
  background-color: $secondary-background !important;
  color: var($--theme-text-color) !important;
}

.MuiTable-root {
  tr[level="1"] td:not(.MuiTableCell-paddingNone):nth-of-type(2) {
    &::before {
      font-family: "Font Awesome 6 Pro";
      font-weight: 400;
      padding-right: 10px;
      color: $secondary;
      content: "\f142";
    }

    //padding-left: 26px;
  }

  tr[level="2"] td:not(.MuiTableCell-paddingNone):nth-of-type(2) {
    &::before {
      font-family: "Font Awesome 6 Pro";
      font-weight: 400;
      padding-right: 10px;
      color: $secondary;
      content: "\f142\f142";
    }

    padding-left: 26px;
  }

  tr[level="3"] td:not(.MuiTableCell-paddingNone):nth-of-type(2) {
    &::before {
      font-family: "Font Awesome 6 Pro";
      font-weight: 400;
      padding-right: 10px;
      color: $secondary;
      content: "\f142\f142\f142";
    }

    padding-left: 36px;
  }

  tr[level="4"] td:not(.MuiTableCell-paddingNone):nth-of-type(2) {
    &::before {
      font-family: "Font Awesome 6 Pro";
      font-weight: 400;
      padding-right: 10px;
      color: $secondary;
      content: "\f142\f142\f142\f142";
    }

    padding-left: 46px;
  }
}

.condensed{
  .MuiTableCell-root {
    padding: 8px;
    font-weight: 300 !important;
    font-size: 0.8em !important;
    border-bottom: 1px solid $border-color-custom !important;

  }
}
.tight{
  .MuiTableCell-root {
    padding: 8px;
  }
}

.bg-variant {
  .MuiTable-root{
    background-color: $background-color;
    color: var($--theme-text-color);
  }

  .MuiTableBody-root .MuiTableRow-root:hover{
    .MuiTableCell-root {
      background-color: var($--theme-secondary-bg-color) !important;
    }
  }

  .MuiTableCell-root {
    padding: 8px;
  }
}