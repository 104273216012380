@import "../variables";
.react-grid-item{

  &.react-draggable{
    outline: 2px dashed $primary;
    border-radius: $border-radius;

    .form-control:disabled{
      border: 1px solid $separator-color;
    }

    .react-resizable-handle {
      background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI2LjEuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCA2IDYiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDYgNjsiIHhtbDpzcGFjZT0icHJlc2VydmUiPgo8c3R5bGUgdHlwZT0idGV4dC9jc3MiPgoJLnN0MHtmaWxsOm5vbmU7fQoJLnN0MXtmaWxsOiMxREEyREM7fQo8L3N0eWxlPgo8cGF0aCBjbGFzcz0ic3QwIiBkPSJ6Ii8+CjxwYXRoIGNsYXNzPSJzdDEiIGQ9Ik02LDZIMFY0LjJoNGgwLjJWMEg2VjZMNiw2eiIvPgo8cGF0aCBjbGFzcz0ic3QwIiBkPSJ6Ii8+Cjwvc3ZnPgo=);
    }

  }
  .react-grid-edit{
    position: absolute;
    right:3px;
    top: 3px;
    color: $primary;
    font-size: 0.8em;
    z-index: 10;
    display: none;
  }
  &:hover .react-grid-edit{
    display: block;
    cursor: pointer;
  }
}
.react-grid-container{
  .react-grid-editEnable{
    position: absolute;
    right:3px;
    top: 3px;
    color: $primary;
    font-size: 0.8em;
    z-index: 10;
    display: none;
  }
  &:hover .react-grid-editEnable{
    display: block;
    cursor: pointer;
  }

  h6 {
    font-family: $font-alt;
    font-size: 1.1em;
    color: $primary;
    margin-bottom: 15px;
  }

}
