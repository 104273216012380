@import "../variables";
#appLoading{
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  color: $text-muted;
  font-size: 0.8em;
  background-color: $secondary-background;
  z-index: 9000;

  .container{
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    //border: 1px solid #f00;
    max-width: 500px;
    text-align: center;
  }

  img {
    &.logo{
      display: none;
      max-width: 200px;
    }
  }


  &.loaded {
    animation : fadeout 800ms linear forwards;
  }

  h1 {
    color: $gray-100
  }

}

@keyframes fadeout{
  99%{
    opacity : 0;
    height : initial;
    width: initial;
    left: initial;
  }
  100%{
    opacity : 0;
    height : 0;
    width: 0;
    left: -1000px;
  }
}