$font-alt : 'Barlow Semi Condensed', sans-serif;

$--theme-primary: --theme-primary;
$--theme-primary-alt: --theme-primary-alt;
$--theme-text-color: --theme-text-color;
$--theme-secondary-text-color: --theme-secondary-text-color;
$--theme-muted-text-color: --theme-muted-text-color;
$--theme-bg-color: --theme-bg-color;
$--theme-bg: --theme-bg;
$--theme-bg-size: --theme-bg-size;
$--theme-bg-alt: --theme-bg-alt;
$--theme-secondary-bg-color: --theme-secondary-bg-color;
$--theme-secondary: --theme-secondary;
$--theme-border-custom-color: --theme-border-custom-color;
$--theme-separator-color: --theme-separator-color;
$--theme-sd-bg-color: --theme-sd-bg-color;

$theme-map-dark: (
        $--theme-primary: #5b9fe1,
        $--theme-primary-alt: lighten(#5b9fe1,10%),
        $--theme-text-color: #eeeeee,
        $--theme-secondary-text-color: #969696,
        $--theme-muted-text-color: #757575,
        $--theme-bg-color: rgb(27, 25, 27),
        $--theme-bg-alt: #1b191b,
        $--theme-bg: rgb(27, 25, 27),
        $--theme-bg-size: contain,
        $--theme-secondary-bg-color: #232223,
        $--theme-secondary: #c297dc,
        $--theme-border-custom-color: rgba(173, 173, 173, 0.2),
        $--theme-separator-color: #424242,
        $--theme-sd-bg-color: #232223,
);

// Override the default dark theme
$theme-map-light: (
        $--theme-primary: #5b9fe1,
        $--theme-primary-alt: lighten(#5b9fe1,5%),
        $--theme-text-color: #6c757d,
        $--theme-secondary-text-color: #202020,
        $--theme-muted-text-color: #98a6ad,
        $--theme-bg-color: #ebeff2,
        $--theme-bg-alt: #fafafa,
        $--theme-bg: #ebeff2,
        $--theme-bg-size: cover,
        $--theme-secondary-bg-color: #ffffff,
        $--theme-secondary: #6c757d,
        $--theme-border-custom-color: rgba(173, 173, 173, 0.25),
        $--theme-separator-color: rgba(173, 173, 173, 0.5),
        $--theme-sd-bg-color: #fafafa,
);

$theme-map-cf: (
        $--theme-primary: #5b9fe1,
        $--theme-primary-alt: lighten(#5b9fe1,5%),
        $--theme-text-color: #6c757d,
        $--theme-secondary-text-color: #202020,
        $--theme-muted-text-color: #98a6ad,
        $--theme-bg-color: #ebeff2,
        $--theme-bg-alt: #fafafa,
        $--theme-bg: url("../assets/img/back/gray-bg.png") no-repeat center center fixed,
        $--theme-bg-size: cover,
        $--theme-secondary-bg-color: #ffffff,
        $--theme-secondary: #6c757d,
        $--theme-border-custom-color: rgba(173, 173, 173, 0.25),
        $--theme-separator-color: rgba(173, 173, 173, 0.5),
        $--theme-sd-bg-color: #fafafa,
);

$body-bg: var($--theme-bg-color);
$primary: var($--theme-primary);
$link-color: $primary;

$secondary-background: var($--theme-secondary-bg-color);
$border-color-custom: var($--theme-border-custom-color);

$separator-color-light: #313131;
$separator-color: var($--theme-separator-color);
$background-color: var($--theme-bg-alt);
$foreground-color: var($--theme-secondary-bg-color);
$input-background: var($--theme-secondary-bg-color);

$success: #00aa00;

$dark-btn-background: #8d8d8d;
$light-btn-background: #e4e4e4;
$button-text-color: #d0d0d0;

$theme-color-1: $primary;
$theme-color-2: #4682B4;
$theme-color-3: #6c90a1;
$theme-color-4: #365573;
$theme-color-5: #47799a;
$theme-color-6: #8e9599;

$primary-color: var($--theme-text-color);
$secondary-color: var($--theme-secondary-text-color);
$muted-color: var($--theme-muted-text-color);

$gradient-color-1 : #225986;
$gradient-color-2 : #1a3a53;
$gradient-color-3 : #145388;


$gray-900: $secondary-background;
$sidebar-bg-color: var($--theme-sd-bg-color);
$sidebar-footer-height: 64px;
$main-fixe: 12px;

$shadowOffsetsTop : 1 3 10 14 19;
$shadowBlursTop: 2 6 10 14 19;
$shadowOpacitiesTop: 0.1 0.3 0.6 0.7 0.8;

$shadowOffsetsBottom : 1 3 6 10 15;
$shadowBlursBottom: 3 6 6 5 6;
$shadowOpacitiesBottom: 0.1 0.3 0.6 0.7 0.8;

$navbar-height: 100px;
$navbar-height-xs: 70px;
$navbar-height-md: 80px;
$navbar-height-lg: 90px;
$form-label-font-size: 0.9em;
$navbar-width: 270px;
$grid-breakpoints: (
        xs: 0,
        sm: 576px,
        md: 768px + $navbar-width,
        lg: 992px + $navbar-width,
        xl: 1200px + $navbar-width,
        xxl: 1400px + $navbar-width
) !default;
