$page-width: 820px;
$page-small-text: 0.875em;

$page-classic-font-size: 14px;
$page-classic-padding: 16px;
$page-classic-amount-width: 125px;
$page-classic-logo-width: 175px;
$page-classic-header-width: round(($page-width - $page-classic-logo-width) / 2);
$page-classic-border-color: #d4dde3;
$page-classic-background-alt: #f4f5f5;

.page{

  %header-cell {
    text-transform: inherit;
    font-weight: 600;
    padding-top: 10px;
    padding-bottom: 10px;
    color: #ffffff;
  }

  .body {
    color: #1c252c;
    font-weight: normal;
    box-shadow: 0 2px 8px #0003;
    margin: 0 auto;
    width: $page-width;
  }

  .page-table {

    font-size: $page-classic-font-size;

    &__header {
      .page-table__cell {
        @extend %header-cell;

        &--amount {
          @extend %header-cell;
        }
      }
    }

    &__cell {
      padding-left: 5px;
      padding-right: 10px;
      vertical-align: top;
      white-space: pre-line; 
      word-break: break-word;

      &:first-child {
        padding-left: 30px;
      }

      &--smaller {
        width: 105px;
        white-space: pre-line;
      }

      &--amount {
        padding-left: 15px;
        padding-right: 15px;
        width: $page-classic-amount-width;
        vertical-align: top;
        white-space: pre-line;

        &:last-child {
          padding-right: 30px;
        }
      }
    }

    &__row {
      border-bottom: inherit;

      .page-table__cell--nested {
        color: #4c5357;
        font-size: $page-classic-font-size;
      }
    }
  }


  &--divider, &--divider--whitespace {
    display: block;
    height: 0;
    padding: 0;
    border: none;
    border-bottom: 1px solid $page-classic-border-color;
    overflow: hidden;
    margin: 24px auto;
  }

  .classic{
    background: #fff;
    border: 1px
    solid #dee1e2;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    font-size: $page-classic-font-size;
    min-height: 1024px;
    padding: $page-classic-padding;
    position: relative;

    &__divider {
      &--bold {
        .page--divider {
          border-width: 3px;
          margin-top: 0;
          margin-bottom: $page-classic-padding;
        }
      }

      &--small-margin {
        .page--divider {
          margin: ($page-classic-padding - 4px) auto;
        }
      }

      &--full-width {
        .page--divider {
          margin-left: -$page-classic-padding;
          margin-right: -$page-classic-padding;
        }
      }
    }

    &__header {
      display: flex;
      margin: 25px 14px 0;
      justify-content: space-between;

      &__logo {
        padding-right: 25px;
        width: ($page-classic-logo-width + 25px);
        img {
          max-height: 125px;
          max-width: $page-classic-logo-width;
        }
      }

      &__address {
        font-size: $page-small-text;
        line-height: 1.2em;
        &.tcf {
          width: ($page-classic-header-width * 1.33);
        }
        //width: $page-classic-header-width;
      }

      &__info {
        text-align: right;
        width: ($page-classic-header-width + 50px);

        &.tcf {
          text-align: left;
          width: ($page-classic-header-width - 50px);
        }

        &--title {
          font-size: 25px;
          margin-bottom: 0;
          margin-top: 0;
        }
        &--reference {
          font-size: 18px;
          font-weight: bold;
          margin-bottom: 0;
          margin-top: 0;
        }
      }

    }

    &__addresses {
      margin-left: 0;
      display: flex;
      justify-content: space-between;


      &__address {
        padding-right: $page-classic-padding;
        line-height: 1.2em;
        font-size: $page-small-text;
        //width: ($page-width / 3);
        &.tcf {
          font-size: 1.1em;
        }
      }

    }

    &__moreinfo {
      border-left: 1px solid $page-classic-border-color;
      padding-right: ($page-classic-padding *2);
      margin-left: -14px;
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      text-align: left;

      &__title{
        background: $page-classic-background-alt;
        padding-top: 5px;
        padding-bottom: 5px;
        padding-left: 10px;
        font-weight: 600;
      }

      p{
        margin: 0;
        padding: 0;
      }

    }

    &__detail {
      margin-left: -$page-classic-padding;
      margin-right: -$page-classic-padding;

      &__table {
        width: 100%;

        thead {
          background-color: $page-classic-background-alt;
          border-top: 1px solid $page-classic-border-color;
          border-bottom: 1px solid $page-classic-border-color;
          th{
            padding: 10px 5px;
          }
        }

        tbody {
          tr{
            border-top: 1px dashed $page-classic-border-color;
            &:last-child{
              border-bottom: 2px solid $page-classic-border-color;
            }
            &:hover{
              background-color: rgba(136, 161, 175, 0.25);
            }

            td{
              vertical-align: top;
              padding: 10px 25px 10px 0;
            }

          }

          tr.subs{
            border-top: none;
            //background-color: rgba(136, 161, 175, 0.1);
            td{
              vertical-align: top;
              padding: 0 25px 20px 0;
            }
          }
        }

        tr > td:first-child, tr > th:first-child {
          padding-left: ($page-classic-padding * 2);
          width: $page-classic-padding;
        }
        tr > td:last-child, tr > th:last-child {
          padding-right: ($page-classic-padding *2);
        }

      }

    }

    &__totals {
      display: flex;
      margin-bottom: 35px;
      margin-left: -16px;
      margin-right: -16px;

      &__blank {
        flex-grow: 5;
      }

      &__amounts {
        display: flex;
        flex-direction: column;
        text-align: right;
        margin-right: 30px;

        &__divider{
          background-color: $page-classic-border-color;
          height: 1px;
          margin: 10px 0;
        }

        &__line {
          display: flex;
          justify-content: flex-end;
          align-items: center;

          &__amount {
            margin-left: 30px;
            min-width: $page-classic-amount-width;
          }
        }
      }
    }


    &__items {
      margin-top: 26px;
      margin-right: -16px;
      margin-left: -16px;

      &__column {
        &--center {
          text-align: center;
        }
      }

      .no-items-message {
        text-align: center;
      }
    }

    .header-details {
      margin-top: 10px;
      float: right;

      .page-table__row:hover {
        background: none;
      }

      .page-table__cell {
        padding-top: 0;
        padding-bottom: 0;

        &:first-child {
          text-align: right;
        }

        &:last-child {
          padding-left: 0;
        }
      }

      .page-table__row:last-child {
        background: $page-classic-background-alt;

        .page-table__cell {
          padding-top: 5px;
          padding-bottom: 5px;
        }

        .page-table__cell:nth-child(2) {
          //padding-right: 30px;
          min-width: $page-classic-amount-width;

          .page-text--body {
            font-weight: 600;
          }
        }
      }
    }
    
  }



}