@import "../variables";
.modal {
  .modal-header,
  .modal-body,
  .modal-footer {
    padding: 1.75rem;
  }
  .modal-title {
    color: var($--theme-text-color);
  }
  .modal-header {
    border-bottom: 1px solid $separator-color;
  }

  .modal-footer {
    border-top: 1px solid $separator-color;
  }

  .close {
    color: $primary-color;
    text-shadow: initial;
  }

  .card.bg-variant {
    background-color: $background-color;
    color: var($--theme-text-color);
  }

}

.modal-content {
  border: initial;
  border-radius: 0.1rem;
  background: $foreground-color;
  color: var($--theme-text-color);
  @include depth(2);
}

.modal-dialog {

}

.modal-right {
  padding-right: 0 !important;

  &.modal-dialog {
    margin: 0 auto;
    margin-right: 0;
    margin-top: 0;
    margin-bottom: 0;
    height: 100%;
    max-width: 380px;
    &.modal-right-lg{
      max-width: 500px;
    }
    &.modal-right-xl{
      max-width: 1000px;
    }
  }

  .modal-content {
    min-height: 100%;
  }

  .modal-header {
    height: $navbar-height;

      @include respond-below(xl) {
        height: $navbar-height-lg;
      }

      @include respond-below(lg) {
        height: $navbar-height-md;
      }

      @include respond-below(sm) {
        height: $navbar-height-xs;
      }
  }

  .modal-footer {
    justify-content: center;
  }

  .modal.fade .modal-dialog {
    transform: translate(25%, 0) !important;
  }

  .modal.show .modal-dialog {
    transform: translate(0, 0) !important;
  }
}


.modal-90w {
  width: 90%;
  max-width: none!important;
}
.modal-maxw {
  max-width: 1500px !important;
}


@include respond-below(lg) {
  .modal-md-90w {
    width: 90%;
    max-width: none !important;
  }
  height: $navbar-height-md;
}

.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}

.modal-full {
  padding-right: 0 !important;

  .modal-dialog {
    margin: 0 auto;
    margin-right: 0;
    margin-top: 0;
    margin-bottom: 0;
    height: 100%;
    width: 100%;

  }
  .modal-content {
    background-color: $background-color;
    min-height: 100%;
    min-width: 100%;
  }

  .modal-body {
    max-height: calc(100vh - 190px);
    overflow-y: auto
  }

  .modal-footer {
    background-color: $foreground-color;
    text-align: right;
  }

  .modal.fade .modal-dialog {
    transform: translate(25%, 0) !important;
  }

  .modal.show .modal-dialog {
    transform: translate(0, 0) !important;
  }
}

.modal-page {
  width: 820px !important;
  max-width: none !important;

  .modal-header{
    padding: 0;
    z-index: 2;
  }

  .modal-body{
    margin-top: -25px;
    z-index: 1;
    padding: 0;
  }
}