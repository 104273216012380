@import "../variables";
h1, h2, h3, h4, h5, h6{
  font-family: $font-alt;
}
h1{
  font-size: 2em;
}
h2{
  font-size: 1.8em;
}
h3{
  font-size: 1.6em;
}

.btn-outline-primary, a,
{
  color: var($--theme-primary);
  &:hover{
    color: var($--theme-text-color);
  }
}
.link-primary, .btn-link,
{
  color: var($--theme-primary) !important;
  &:hover{
    color: var($--theme-text-color) !important;
  }
}

.text-primary {
  color: var($--theme-primary) !important;
}

.text-body {
  color: var($--theme-text-color) !important;
}

.text-muted {
  color: var($--theme-muted-text-color) !important;
}

.text-italic { font-style: italic; }

.btn-outline-primary, .btn-primary {
  border-color: var($--theme-primary) !important;
}
.btn-primary {
  background-color: var($--theme-primary) !important;
}

.text-align-right {
  text-align: right;
}
.text-align-center {
  text-align: center;
}

.text-bold {
  font-weight: bold;
}