@import "../../variables";
.lead-select__value-container {
  outline: initial !important;
  box-shadow: none !important;
  padding: 0rem 0.75rem 1.24rem 0.75rem !important;
  background: $foreground-color !important;
}

.react-select .lead-select__dropdown-indicator {
  color: $primary-color;
}

.react-select .lead-select__menu-list {
  padding-bottom: 0;
  padding-top: 0;
}

.react-select .lead-select__single-value,
.react-select .lead-select__input,
.react-select .lead-select__multi-value__label {
  color: $theme-color-1;
  font-family: $font-alt;
  font-size: 1.5em;
}

.react-select .lead-select__dropdown-indicator,
.react-select .lead-select__control--is-focused .lead-select__dropdown-indicator,
.react-select .lead-select__clear-indicator,
.react-select .lead-select__control--is-focused .lead-select__clear-indicator {
  outline: initial;
  box-shadow: initial;

  &:active,
  &:focus,
  &:hover {
    color: $theme-color-1 !important;
  }
}

.lead-select__control {
  border-radius: $border-radius !important;
  border: 1px solid $separator-color !important;
  background: $theme-color-1 !important;
  outline: initial !important;
  box-shadow: none !important;

  &.lead-select__control--is-disabled{
    border: none !important;

    .lead-select__dropdown-indicator {
      display: none !important;
    }

  }
}

.lead-select__indicator-separator {
  display: none;
}

.lead-select__dropdown-indicator svg {
  width: 15px;
  height: 15px;
}

.lead-select__option {
  background: $foreground-color !important;
  color: $primary-color !important;

  &:hover,
  &:active {
    background: $theme-color-1 !important;
    color: $button-text-color !important;
  }
}

.lead-select__option--is-selected {
  background: $theme-color-1 !important;
  color: $button-text-color !important;
}

.lead-select__control--is-focused {
  border-color: rgba($theme-color-1, 0.6) !important;
}

.lead-select__multi-value {
  background: transparent !important;
  border: 1px solid $separator-color !important;
}

.lead-select__multi-value__remove:hover,
.lead-select__multi-value__remove:active {
  background: transparent !important;
  color: $theme-color-1 !important;
}

.react-select .lead-select__menu {
  border-radius: $border-radius !important;
  z-index: 20 !important;
  box-shadow: initial !important;
  border: 1px solid rgba($theme-color-1, 0.6) !important;
  border-top: initial !important;
  margin-top: -1px !important;
  background-color: $foreground-color !important;
  border-width: 1px !important;
}

.lead-select__single-value {
  bottom: 0 !important;
  top: 50% !important;
  padding-top: 2px !important;
}