.frameset{
  position: relative;
  border: 1px solid $border-color-custom;
  border-radius: $border-radius;
  padding: 20px 15px 15px;

  &-title{
    display: inline-block;
    position: absolute;
    padding: 0 5px;
    left: 15px;
    top: -15px;
    background-color: $secondary-background;
  }
  .row{
    font-size: 0.85rem;
  }
}