@import "../variables";
.iwbdnL {
  margin-top: 15px;
  padding: 5px;
  color: $primary-color !important;
  background-color: transparent !important;

  .iGmPpV svg * {
    fill: $primary;
    stroke: $primary;
  }
  .hSEABz {
    border-left: 4px solid $primary;
    background-color: $separator-color !important;
  }


}

.tree-products{
  .rct-node-leaf .rct-title{
    font-size: 0.85em;
  }
}