@import "../variables";

$border-line: 1px solid var($--theme-separator-color);
.card {
  background-color: $secondary-background;
  border: $border-line;
  color: var($--theme-text-color);

  .card-header {
    border-bottom: none; //$border-line;
    //padding-bottom: 0;
    background-color: $secondary-background;
    h1,h2,h3,h4,h5,h6 {
      font-weight: 300;
      letter-spacing: 0.05em;
      color: $primary;
      margin-bottom: 0;
    }
  }
  .card-footer{
    border-top: $border-line;
  }

}

.card-selectable{
  cursor: pointer;
}
.card-hover{
  &:hover{
    background-color: $background-color;
  }
}