@import "../variables";
.sidebar-toggle{
  float: left;
  font-size: 20px;
  margin: -2px 15px 0 5px;
}

.dark{
  .pro-sidebar {
    border-left: 1px solid #313131;
    box-shadow: 0 3px 30px RGB(0 0 0 / 30%), 0 3px 24px RGB(0 0 0 / 30%);
  }
}
.light{
  .pro-sidebar {
    box-shadow: 0 1px 15px RGB(0 0 0/10%), 0 1px 8px RGB(0 0 0/10%);
    color: var($--theme-text-color);

    .pro-menu > ul > {
      .pro-menu-item {
        border-bottom: 1px solid var($--theme-border-custom-color);
      }
      .pro-sub-menu > .pro-inner-list-item {
        background-color: var($--theme-bg-color);
      }
    }

    .pro-menu .pro-menu-item > {
      .pro-inner-item:focus, .pro-inner-item:hover {
        background-color: var($--theme-secondary-bg-color);
        color: var($--theme-text-color);
      }
    }

    .pro-menu.shaped .pro-menu-item > .pro-inner-item > .pro-icon-wrapper {
      background-color: var($--theme-bg);
    }

  }

  .pro-sidebar.collapsed .pro-menu > ul > .pro-menu-item.pro-sub-menu > .pro-inner-list-item > .popper-inner {
    background-color: var($--theme-bg-color);
    border: 1px solid var($--theme-separator-color);
    box-shadow: 0 1px 15px RGB(0 0 0/10%), 0 1px 8px RGB(0 0 0/10%);
  }

}

@include media-breakpoint-down(sm) {
  .pro-sidebar {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
  }
  &.collapsed {
    min-width: 0 !important;
    width: 0 !important;
  }
  .sidebar-toggle{
    padding: 15px;
    font-size: 1.8em;
    color: $gray-500;
    &.open {
      color: $primary
    }
  }
}

@include media-breakpoint-down(md) {
  .pro-sidebar {
    position: absolute;
    top: 0;
    bottom: 0;
  }
  &.collapsed {
    min-width: 0 !important;
    width: 0 !important;
  }
  .sidebar-toggle{
    padding: 15px;
    font-size: 1.8em;
    color: $gray-500;
    &.open {
      color: $primary
    }
  }
}

.pro-sidebar-header, .navbar-expand {
  max-height: 60px;
  height: 60px;
}

.sidebar-header-title{
  padding: 20px 24px;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 14px;
  letter-spacing: 1px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  img{ max-width: 125px; }
}

.pro-sidebar-content{
  overflow: hidden;
  padding-bottom: 43px;

  h5 {
    margin-bottom: 0;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: inline-block;
  }

  .title{
    padding: 5px 15px 5px 30px;
    border-bottom: 1px solid $border-color-custom;
  }
  .dropdown{
    padding: 5px;
    border-bottom: 1px solid $border-color-custom;
    button {
      width: 100%;
    }
  }


}
.pro-sidebar .pro-menu.sidebar-bottom-menu {
  border-top: 1px solid $border-color-custom;
  bottom: $sidebar-footer-height;
  left: 0;
  right: 0;
  position: absolute;
  background-color: $sidebar-bg-color;
  padding: 0;
  .pro-inner-item {
    padding: 4px 35px 4px 20px !important;
  }
}

.pro-sidebar-footer{
  padding: 15px 24px;
  font-size: 0.75em;
  letter-spacing: 1px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  height: $sidebar-footer-height;
  min-height: $sidebar-footer-height;
  img{ max-width: 32px;}
}

.pro-sidebar .pro-menu .pro-menu-item.active,
.pro-sidebar:not(.collapsed) .pro-menu .pro-sub-menu.highlight:not(.open) > .pro-inner-item,
.pro-sidebar.collapsed .pro-menu .pro-sub-menu.highlight > .pro-inner-item
{
  color: $primary;
}

.pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu .pro-inner-list-item .pro-inner-item:before {
  content: none !important;
}

.pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu .pro-inner-list-item .pro-inner-item .pro-arrow-wrapper,
.pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu .pro-inner-list-item .pro-inner-item > .pro-icon-wrapper
{
  display: block;
}

.pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu .pro-inner-list-item .pro-inner-item > .pro-icon-wrapper {
  margin-right: 12px;
  margin-left: 5px;
  font-size: 14px;
  width: 10px;
  min-width: 0;
  height: 0;
  line-height: 0;
}

.app-logo{
  max-height: 25px;
}