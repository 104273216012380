@import "../variables";
$input-font-size: 0.9rem;

.av-invalid .av-label,
.form-group.text-danger,
.was-validated .custom-control-input:invalid ~ .custom-control-label,
.custom-control-input.is-invalid ~ .custom-control-label,
.was-validated .form-check-input:invalid ~ .form-check-label,
.form-check-input.is-invalid ~ .form-check-label {
  color: $primary-color !important;
}

.was-validated .form-control:invalid,
.form-control.is-invalid,
.was-validated .custom-select:invalid,
.custom-select.is-invalid,
.was-validated .form-control:invalid:focus,
.form-control.is-invalid:focus,
.was-validated .custom-select:invalid:focus,
.custom-select.is-invalid:focus {
  border-color: $separator-color;
  background: initial;
}

.invalid-feedback {
  border-radius: $border-radius;
  padding: 0.5rem 1rem;
  font-size: 0.76rem;
  color: $primary-color;
  background: $foreground-color;
  border: 1px solid $theme-color-1;
  text-align: center;
  width: unset !important;
  position: absolute;
  z-index: 4;
  margin-top: -0.5rem;
  left: 50%;
  transform: translateX(-50%);

  &::before {
    content: "";
    position: absolute;
    top: -5px;
    left: -2.5px;
    margin-left: 50%;
    width: 10px;
    height: 5px;
    border-bottom: solid 5px $theme-color-1;
    border-left: solid 5px transparent;
    border-right: solid 5px transparent;
  }

  &::after {
    content: "";
    position: absolute;
    top: -4px;
    left: -2.5px;
    margin-left: 50%;
    width: 10px;
    height: 5px;
    border-bottom: solid 5px $foreground-color;
    border-left: solid 5px transparent;
    border-right: solid 5px transparent;
  }
}

.tooltip-right-bottom .invalid-feedback {
  left: initial;
  right: 0;
  transform: translateX(0);

  &::before,
  &::after {
    left: initial;
    right: 25px;
    margin-left: 0;
  }
}

.tooltip-left-bottom .invalid-feedback {
  left: 0;
  right: initial;
  transform: translateX(0);

  &::before,
  &::after {
    left: 25px;
    right: initial;
    margin-left: 0;
  }
}

.tooltip-center-top .invalid-feedback {
  bottom: 80%;
  transform: translateX(-50%) translateY(50%);
  top: initial;

  &::before {
    content: "";
    position: absolute;
    top: initial;
    bottom: -5px;
    border-top: solid 5px $theme-color-1;
    border-bottom: initial;
  }

  &::after {
    content: "";
    position: absolute;
    top: initial;
    bottom: -4px;
    border-top: solid 5px $foreground-color;
    border-bottom: initial;
  }
}

.tooltip-right-top .invalid-feedback {
  bottom: 80%;
  transform: translateX(0) translateY(50%);
  top: initial;
  left: initial;
  right: 0;

  &::before {
    content: "";
    position: absolute;
    top: initial;
    bottom: -5px;
    border-top: solid 5px $theme-color-1;
    border-bottom: initial;
    left: initial;
    right: 25px;
    margin-left: 0;
  }

  &::after {
    content: "";
    position: absolute;
    top: initial;
    bottom: -4px;
    border-top: solid 5px $foreground-color;
    border-bottom: initial;
    left: initial;
    right: 25px;
    margin-left: 0;
  }
}

.tooltip-left-top .invalid-feedback {
  bottom: 80%;
  transform: translateX(0) translateY(50%);
  top: initial;
  left: 0;
  right: initial;

  &::before {
    content: "";
    position: absolute;
    top: initial;
    bottom: -5px;
    border-top: solid 5px $theme-color-1;
    border-bottom: initial;
    left: 25px;
    right: initial;
    margin-left: 0;
  }

  &::after {
    content: "";
    position: absolute;
    top: initial;
    bottom: -4px;
    border-top: solid 5px $foreground-color;
    border-bottom: initial;
    left: 25px;
    right: initial;
    margin-left: 0;
  }
}

.tooltip-label-right .invalid-feedback {
  transform: translateX(0) translateY(-50%);
  top: 16px;
  left: 50px;

  &::before {
    content: "";
    position: absolute;
    left: -5px;
    right: initial;
    margin-left: 0;
    border: initial;
    border-top: solid 5px transparent;
    border-bottom: solid 5px transparent;
    border-right: solid 5px $theme-color-1;
    bottom: initial;
    top: 12px;
    width: 5px;
  }

  &::after {
    content: "";
    position: absolute;
    left: -4px;
    right: initial;
    margin-left: 0;
    border: initial;
    border-top: solid 5px transparent;
    border-bottom: solid 5px transparent;
    border-right: solid 5px $foreground-color;
    bottom: initial;
    top: 12px;
    width: 5px;
  }
}

.error-l-0 .invalid-feedback {
  left: 0;
}

.error-l-25 .invalid-feedback {
  left: 25px;
}

.error-l-50 .invalid-feedback {
  left: 50px;
}

.error-l-75 .invalid-feedback {
  left: 75px;
}

.error-l-100 .invalid-feedback {
  left: 100px;
}

.error-l-125 .invalid-feedback {
  left: 125px;
}

.error-l-150 .invalid-feedback {
  left: 150px;
}

.error-l-175 .invalid-feedback {
  left: 175px;
}

.error-l-200 .invalid-feedback {
  left: 200px;
}

.error-l-225 .invalid-feedback {
  left: 225px;
}

.error-l-250 .invalid-feedback {
  left: 250px;
}

.error-l-275 .invalid-feedback {
  left: 275px;
}

.error-t-negative .invalid-feedback {
  top: -10px;
}


.form-check-label,
.custom-control-label {
  line-height: 24px !important;
}

.react-tagsinput {
  background-color: $input-background !important;
  border: 1px solid $separator-color !important;
  outline: initial !important;
  box-shadow: initial !important;
}

.react-tagsinput--focused {
  border: 1px solid rgba($theme-color-1, 0.6) !important;
}

.react-tagsinput-tag {
  background: $theme-color-1 !important;
  border-radius: 15px !important;
  padding: 0 10px !important;
  margin-bottom: 0 !important;
  display: inline-block !important;
  font-size: 12px !important;
  color: $foreground-color !important;
  border: initial !important;
}

.form-control:focus, .form-control:disabled {
  background: $input-background;
  color: $primary-color;
}

.form-control:disabled{
  border: none;
}

.bootstrap-tagsinput {
  width: 100%;
}

.bootstrap-tagsinput input {
  padding: 0;
}

.form-control:focus {
  border-color: rgba($theme-color-1, 0.6);
}

select.form-control:not([size]):not([multiple]) {
  height: initial;
}

.btn-group-icon {
  line-height: 22px;
}

@-webkit-keyframes pulse-inner {
  0% {
    -webkit-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }

  10% {
    -webkit-transform: scale(0.8, 1);
    -moz-transform: scale(0.8, 1);
    -ms-transform: scale(0.8, 1);
    -o-transform: scale(0.8, 1);
    transform: scale(0.8, 1);
  }

  20% {
    -webkit-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }
}

@-moz-keyframes pulse-inner {
  0% {
    -webkit-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }

  10% {
    -webkit-transform: scale(0.8, 1);
    -moz-transform: scale(0.8, 1);
    -ms-transform: scale(0.8, 1);
    -o-transform: scale(0.8, 1);
    transform: scale(0.8, 1);
  }

  20% {
    -webkit-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }
}

@keyframes pulse-inner {
  0% {
    -webkit-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }

  10% {
    -webkit-transform: scale(0.8, 1);
    -moz-transform: scale(0.8, 1);
    -ms-transform: scale(0.8, 1);
    -o-transform: scale(0.8, 1);
    transform: scale(0.8, 1);
  }

  20% {
    -webkit-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }
}

.dropzone .dz-preview:not(.dz-processing) .dz-progress {
  -webkit-animation: pulse-inner 3s ease infinite;
  -moz-animation: pulse-inner 3s ease infinite;
  -ms-animation: pulse-inner 3s ease infinite;
  -o-animation: pulse-inner 3s ease infinite;
  animation: pulse-inner 3s ease infinite;
}

.custom-switch {
  display: block !important;
  background: $separator-color !important;
  width: 58px !important;
  height: 27px !important;
  border: 1px solid $separator-color !important;

  &:after {
    width: 18px !important;
    height: 18px !important;
    top: 3px !important;
    margin-left: 3px !important;
    box-shadow: initial;
    transform: initial !important;
    animation-name: unset !important;
    background: $foreground-color !important;
  }
  &.rc-switch:after {
    box-shadow: none !important;
  }

  &.rc-switch-checked:after {
    left: 30px !important;
    box-shadow: none !important;
  }

  &.rc-switch-checked.custom-switch-primary {
    background: $theme-color-1 !important;
    border: 1px solid $theme-color-1 !important;
  }

  &.rc-switch-checked.custom-switch-secondary {
    background: $theme-color-2 !important;
    border: 1px solid $theme-color-2 !important;
  }

  &.custom-switch-primary-inverse {
    border: 1px solid $separator-color !important;
  }

  &.rc-switch-checked.custom-switch-primary-inverse {
    background: $input-background !important;
    border: 1px solid $theme-color-1 !important;

    &:after {
      background: $theme-color-1 !important;
    }
  }

  &.custom-switch-secondary-inverse {
    border: 1px solid $separator-color !important;
  }

  &.rc-switch-checked.custom-switch-secondary-inverse {
    background: $input-background !important;
    border: 1px solid $theme-color-2 !important;

    &:after {
      background: $theme-color-2 !important;
    }
  }
}

.custom-switch .custom-switch-input + .custom-switch-btn {
  background: $separator-color !important;
}

.custom-switch.custom-switch-primary .custom-switch-input:checked + .custom-switch-btn {
  background: $theme-color-1 !important;
  border: 1px solid $theme-color-1 !important;
}

.custom-switch.custom-switch-secondary .custom-switch-input:checked + .custom-switch-btn {
  background: $theme-color-2 !important;
  border: 1px solid $theme-color-2 !important;
}

.custom-switch.custom-switch-primary-inverse .custom-switch-input + .custom-switch-btn {
  border: 1px solid $separator-color !important;
}

.custom-switch.custom-switch-primary-inverse .custom-switch-input:checked + .custom-switch-btn {
  background: $input-background !important;
  border: 1px solid $theme-color-1 !important;
}

.custom-switch.custom-switch-primary-inverse .custom-switch-input:checked + .custom-switch-btn:after {
  background: $theme-color-1 !important;
}

.custom-switch.custom-switch-secondary-inverse .custom-switch-input + .custom-switch-btn {
  border: 1px solid $separator-color !important;
}

.custom-switch.custom-switch-secondary-inverse .custom-switch-input:checked + .custom-switch-btn {
  background: $input-background !important;
  border: 1px solid $theme-color-2 !important;
}

.custom-switch.custom-switch-secondary-inverse .custom-switch-input:checked + .custom-switch-btn:after {
  background: $theme-color-2 !important;
}

.custom-switch .custom-switch-input + .custom-switch-btn:after {
  background: $input-background !important;
}

.custom-switch .custom-switch-input + .custom-switch-btn {
  border-color: $separator-color !important;
}

.rc-switch.custom-switch.custom-switch-small {
  width: 42px !important;
  height: 23px !important;
}

.rc-switch.custom-switch.custom-switch-small:after {
  width: 14px !important;
  height: 14px !important;
  left: 1px !important;
  top: 3px !important;
}

.rc-switch-checked.custom-switch.custom-switch-small:after {
  left: 18px !important;
}

.input-group-text {
  border-radius: $border-radius;
  background-color: #{rgba(var($--theme-secondary-bg-color), 0.5)};
  border-color: $separator-color;
  color: $primary-color;
  font-size: $input-font-size;
  padding: 0.75rem 0.75rem 0.5rem 0.75rem;
}

.form-group {
  position: relative;
  &.has-float-label {
    margin-top: 10px;
    margin-bottom: 20px;
  }
}

.form-control {
  border-radius: $border-radius;
  outline: initial !important;
  box-shadow: initial !important;
  font-size: $input-font-size;
  padding: 0.75rem;
  line-height: 1.5;
  border: 1px solid $separator-color;
  background: $input-background;
  color: $primary-color;
  height: initial;
}

@keyframes autofill {
  to {
    color: $primary-color;
    background: transparent;
  }
}

input:-webkit-autofill {
  animation-name: autofill;
  animation-fill-mode: both;
}

input:-webkit-autofill {
  -webkit-text-fill-color: $primary-color !important;
}

.form-control-sm,
.input-group-sm > .form-control,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  border-radius: $border-radius;
}

.form-control-lg,
.input-group-lg > .form-control,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  border-radius: $border-radius;
}

.custom-select {
  border-radius: $border-radius;
  padding: 0.75rem 0.75rem 0.5rem 0.75rem;
}

.input-group > .form-control:not(:first-child),
.input-group > .custom-select:not(:first-child) {
  outline: initial !important;
  box-shadow: initial;
}

.custom-select {
  height: calc(2.5rem + 2px);
}

.custom-select:focus {
  border-color: $theme-color-1;
}

.custom-file-input:focus ~ .custom-file-label {
  border-color: rgba($theme-color-1, 0.6);
}

.custom-file-label::after {
  background: $foreground-color;
  color: $primary-color;
  border-color: $separator-color;
}

.custom-file-input {
  box-shadow: initial !important;
}

.custom-file-label {
  background: $foreground-color;
  border-color: $separator-color;
}

.custom-file-label {
  box-shadow: initial !important;
  border-radius: $border-radius;
  height: calc(2.5rem + 2px);
  padding: 0.75rem 0.75rem 0.5rem 0.75rem;
}

.custom-file {
  height: calc(2.5rem + 2px);
}

.custom-file-label:focus,
.custom-file-input:focus {
  border-color: $theme-color-1;
}

.custom-file-label::after {
  height: calc(calc(2.5rem + 2px) - 1px * 2);
  padding: 0.75rem 0.75rem 0.5rem 0.75rem;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
textarea:-webkit-autofill:active,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus,
select:-webkit-autofill:active
{
  border: 1px solid $dark-btn-background !important;
  -webkit-text-fill-color: $secondary-color !important;
  -webkit-box-shadow: 0 0 0 30px $input-background inset !important;
  transition: background-color 5000s ease-in-out 1s !important;
}

.has-float-label {
  display: block;
  position: relative;
}

.has-float-label label::after,
.has-float-label > span::after {
  background: $foreground-color !important;
}


.has-float-label label,
.has-float-label > span {
  color: rgba(var($--theme-text-color), 0.5);
}

.has-float-label label,
.has-float-label > span:last-of-type {
  position: absolute;
  cursor: text;
  font-size: 90%;
  opacity: 1;
  top: -0.4em;
  left: 0.75rem;
  z-index: 3;
  line-height: 1;
  padding: 0 1px;
}

.has-float-label label::after,
.has-float-label > span::after {
  content: " ";
  display: block;
  position: absolute;
  height: 5px;
  top: 3px;
  left: -0.2em;
  right: -0.2em;
  z-index: -1;
}

.has-float-label .form-control::-webkit-input-placeholder {
  opacity: 1;
}

.has-float-label .form-control::-moz-placeholder {
  opacity: 1;
}

.has-float-label .form-control:-ms-input-placeholder {
  opacity: 1;
}

.has-float-label .form-control::placeholder {
  opacity: 1;
}

.has-float-label .form-control:placeholder-shown:not(:focus)::-webkit-input-placeholder {
  opacity: 0;
}

.has-float-label .form-control:placeholder-shown:not(:focus)::-moz-placeholder {
  opacity: 0;
}

.has-float-label .form-control:placeholder-shown:not(:focus):-ms-input-placeholder {
  opacity: 0;
}

.has-float-label .form-control:placeholder-shown:not(:focus)::placeholder {
  opacity: 0;
}

.input-group .has-float-label {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  margin-bottom: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.input-group .has-float-label .form-control {
  width: 100%;
}

.input-group .has-float-label:not(:last-child),
.input-group .has-float-label:not(:last-child) .form-control {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  border-right: 0;
}

.input-group .has-float-label:not(:first-child),
.input-group .has-float-label:not(:first-child) .form-control {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}

.error {
  border: 1px solid $danger;
}
.error-message {
  color: $danger;
  padding: 0 .2em;
  right: 0;
  top: 0.5em;
  height: 1em;
  position: absolute;
  font-size: .8em;
}

.has-float-label .error-message {
  top: -1.3em;
}

.react-select {
  outline: initial !important;
  box-shadow: none !important;
  font-size: $input-font-size;
}

.react-select__value-container {
  outline: initial !important;
  box-shadow: none !important;
  padding: 0.45rem 0.75rem 0.4rem 0.75rem !important;
  background: $foreground-color !important;
}

.react-select .react-select__dropdown-indicator {
  color: $secondary-color;
}

.react-select .react-select__menu-list {
  padding-bottom: 0;
  padding-top: 0;
  border: 1px solid var($--theme-separator-color);
}

.react-select .react-select__single-value,
.react-select .react-select__input,
.react-select .react-select__multi-value__label {
  color: $primary-color;
}

.react-select .react-select__dropdown-indicator,
.react-select .react-select__control--is-focused .react-select__dropdown-indicator,
.react-select .react-select__clear-indicator,
.react-select .react-select__control--is-focused .react-select__clear-indicator {
  outline: initial;
  box-shadow: initial;

  &:active,
  &:focus,
  &:hover {
    color: $theme-color-1 !important;
  }
}

.react-select__control {
  border-radius: $border-radius !important;
  border: 1px solid $separator-color !important;
  background: $input-background !important;
  outline: initial !important;
  box-shadow: none !important;

  &.react-select__control--is-disabled{
    border: none !important;

    .react-select__dropdown-indicator {
      display: none !important;
    }

  }
}

.react-select__indicator-separator {
  display: none;
}

.react-select__dropdown-indicator svg {
  width: 15px;
  height: 15px;
}

.react-select__option {
  background: $foreground-color !important;
  color: $primary-color !important;

  &:hover,
  &:active {
    background: $theme-color-1 !important;
    color: $button-text-color !important;
  }
}

.react-select__option--is-selected {
  background: $theme-color-1 !important;
  color: $button-text-color !important;
}

.react-select__control--is-focused {
  border-color: rgba($theme-color-1, 0.6) !important;
}

.react-select__multi-value {
  background: transparent !important;
  border: 1px solid $separator-color !important;
}

.react-select__multi-value__remove:hover,
.react-select__multi-value__remove:active {
  background: transparent !important;
  color: $theme-color-1 !important;
}

.react-select .react-select__menu {
  border-radius: $border-radius !important;
  z-index: 20 !important;
  box-shadow: initial !important;
  border: 1px solid rgba($theme-color-1, 0.6) !important;
  border-top: initial !important;
  margin-top: -1px !important;
  background-color: $foreground-color !important;
  border-width: 1px !important;
}

.react-select__single-value {
  bottom: 0 !important;
  top: 50% !important;
  padding-top: 2px !important;
}
@import "ReactSelectThemes/lead-select";
@import "ReactSelectThemes/warning-select";

.input-lead {
  font-size: 1.25rem;
  font-weight: bold;
  padding: 0.35rem 0.75rem;
  line-height: 1.5;
  color: $primary;
}

.btn-small {
  font-size: $small-font-size;
}

.form-control.light {
  border-radius: $border-radius;
  outline: initial !important;
  box-shadow: initial !important;
  font-size: $input-font-size;
  padding: 0.75rem;
  line-height: 1.5;
  border: 1px solid $separator-color;
  background: $white;
  color: $color-contrast-dark;
  height: initial;
  &.error {
    border: 3px solid $danger;
  }
}
