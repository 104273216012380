@import "../variables";
.intl-tel-input{
  width: 100%;
  .country-list {
    background-color: $background-color !important;
    border: 1px solid $border-color-custom !important;

    .country {
      &.highlight {
        background-color: $secondary-background !important;
      }
    }

    .divider {
      border-bottom: 1px solid $border-color-custom !important;
    }

  }
}