.fadeInOut {
  -webkit-animation: fadeinout 2s linear forwards;
  animation: fadeinout 2s linear forwards;
  animation-iteration-count: infinite;
}

@-webkit-keyframes fadeinout {
  0%,100% { opacity: 0; }
  50% { opacity: 1; }
}

@keyframes fadeinout {
  0%,100% { opacity: 0; }
  50% { opacity: 1; }
}