@import "../variables";
.dropzone {
  padding: 10px;
  border-radius: $border-radius;
  text-align: center;

  &.light {
    background: white;
  }

  p {
    padding: 20px;
    margin: -10px;
    font-size: 0.8em;
    outline: 2px dashed $separator-color;
    border-radius: $border-radius;
    color: $secondary-color;
    &:hover {
      outline-color: transparent;
      color: $primary;
    }
    height: inherit;
  }

  img {
    max-width: 100%;
    max-height: 150px;
  }

  &:hover, &.active {
    outline: 2px dashed $primary;
    cursor: pointer;
  }

}

.select-zone{

  border-radius: $border-radius;
  outline: 2px dashed $secondary-color;
  text-align: center;
  margin: 25px;
  padding: 50px 15px;
  font-size: 1em;
  vertical-align: middle;

  i{
    font-size: 2em;
    padding-right: 15px;
  }

  &:hover {
    outline-color: transparent;
    color: $primary;
  }

  &:hover, &.active {
    outline: 2px dashed $primary;
    cursor: pointer;
  }
}

.dropzone-field{
  p{
    padding: 11px;
  }
}