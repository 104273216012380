// Override default variables before the import
@import url('https://fonts.googleapis.com/css2?family=Barlow+Semi+Condensed:wght@300&display=swap');

@import '~bootstrap/scss/bootstrap';
@import "variables";
@import "mixins";
@import "animation";

@import '~react-pro-sidebar/dist/scss/styles';
@import 'components/typo';
@import 'components/forms';
@import 'components/commons';
@import 'components/app-loading';
@import "components/sidebar";
@import 'components/material-table';
@import 'components/modal';
@import 'components/cards';
@import 'components/tables';
@import 'components/tabs';
@import 'components/dropzone';
@import 'components/accordion';
@import 'components/react-grid';
@import 'components/react-phone-input';
@import 'components/react-tree';
@import 'components/react-confirm-alert';
@import 'components/react-datepicker';
@import 'components/charts';
@import 'components/page';
@import 'components/frameset';
@import 'components/buttons';


:root.light {
  @include spread-map($theme-map-light);
}
:root.dark {
  @include spread-map($theme-map-dark);
}
:root.cf {
  @include spread-map($theme-map-cf);
}

*, *::before, *::after {
  box-sizing: inherit;
}
html {
  box-sizing: border-box;
}
body {
  margin: 0;
  height: 100vh;
  max-height: -webkit-fill-available;
  overflow: hidden;
  background: var($--theme-bg);
  background-size: var($--theme-bg-size);
}

#root, .app{
  height: 100vh;
  max-height: -webkit-fill-available;
  background: var($--theme-bg);
  background-size: contain;
}

.app {
  color: var($--theme-text-color);
  display: flex;
  position: relative;
  main {
    padding: 0 $main-fixe;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    //overflow-y: auto;
    position: relative;
  }
}

.app-container{
  margin-right: 17px;
  //max-width: 1650px;
}

@include media-breakpoint-down(md) {

  .app main {
    padding: 0;
    .app-container{
      width: 100%;
      max-width: 100%;
      overflow-x: hidden;
    }

  }

}

.navbar {
  margin-left: -$main-fixe;
  margin-right: -$main-fixe;
  box-shadow: 0 1px 15px RGB(0 0 0 / 10%), 0 1px 8px RGB(0 0 0 / 10%);

  .dropdown-menu{
    &[aria-labelledby='my-profile'] { right: 2px; }
    background-color: $sidebar-bg-color;
    border: 1px solid $border-color-custom;
    .dropdown-item{
      text-decoration: none !important;
      color: var($--theme-text-color);
      font-size: 0.8em;
      &:hover{
        background-color: $body-bg;
      }
    }
    .dropdown-divider {
      border-top: 1px solid $border-color-custom;
    }
  }
}

.page-title{
  padding: 10px 15px;
}

.breadcrumb-column {
  position: relative;
  margin-right: 15px;
}
.breadcrumb-container{
  position: absolute;
  right: 15px;
  bottom: 0;

  .breadcrumb-item{
    font-size: 0.85em;
    a {
      color: var($--theme-muted-text-color) ;
      text-decoration: none !important;
      &:hover{
        color: $primary
      }
    }
  }
}
.hr-short{
  margin: 0 0 0 15px;
}

@include media-breakpoint-down(md) {
  .page-title{
    width: 100%;
    padding: 5px 0 0 10px;
    font-size: 1em;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .breadcrumb-column {
    display: none;
  }
}


.loading-overlay {
  z-index: 1050;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}

.loading {
  display: inline-block;
  width: 30px;
  height: 30px;
  border: 2px solid rgba(var($--theme-secondary-bg-color), 0.2);
  border-radius: 50%;
  border-top-color: $sidebar-bg-color;
  animation: spin 1s ease-in-out infinite;
  -webkit-animation: spin 1s ease-in-out infinite;
  left: calc(50% - 15px);
  top: calc(50% - 15px);
  position: fixed;
  z-index: 1;
}

@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}

.my-profile {
  &.show a#my-profile, a#my-profile:hover{
    color: $primary;
  }
  a#my-profile{
    //font-size: 0.8em;
    font: 400 13.3333px Arial;
    color: $gray-300;

    &::after {
      margin-left: 0.4em;
    }
  }

}

.label-debug{
  &:hover{
    text-decoration: dotted;
    .edit-label{
      display: inline-block;
    }
  }
}
.edit-label{
  display: none;
  font-size: 12px;
  color: $muted-color;
  margin-left: 5px;
  &:hover {
    cursor: pointer;
    color: $gray-100;
  }
}

.is_loading{
  width: 100%;
  height: 45px;
  background: url('img/loading-pulse.svg') no-repeat center;
}
span.is_loading{
  display: inline-block;
  width: 75px;
  height: 15px;
}

.icon-edit-button{
  display: inline-block;
  font-size: 12px;
  color: $primary;
  margin-left: 8px;
  &:hover {
    cursor: pointer;
    color: $gray-100;
  }

  @include media-breakpoint-down(md) {
    font-size: 24px;
    position: absolute;
    margin-top: 5px;
  }

}
button.icon-edit-button{
  background-color: transparent;
  padding: 0 3px;
  border: none;

  @include media-breakpoint-down(md) {
    margin-top: -3px;
  }

}

.font-alt{
  font-family: $font-alt;
  font-size: 1.15em;
}
.smaller{
  font-size: 0.8em;
}

[class^="link-"], .pointer{
  cursor: pointer;
}

.test {
  .row{
    //border: 1px solid blue;
  }

  [class^="col-"], .col{
    border: 1px solid red;
  }
}

.showPassword {
  padding: 10px;
  font-size: 1.3em;
  font-width: bold;
  letter-spacing: 0.8em;
  i {
    color: $primary;
    &:hover {
      cursor: pointer;
      color: $primary-color
    }
  }
}

.custom-overlay{
  position: fixed;
  z-index: 1000;
  top: -1000px;
  left: -1000px;
  display: none;
  bottom: 15px;
  min-height: 400px;
}

.bg-variant {
  background-color: $background-color;
  color: var($--theme-text-color);
}

.d-c{
  .d-p{
    display: none;
  }
  &:hover .d-p{
    display: initial;
  }
}