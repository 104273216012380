@import "../variables";


.primary{
  color: var($--theme-primary);
}

.bg-selected{
  background-color: $theme-color-2;
}

.text-separator{
  text-align: center;
  text-transform: lowercase;
  margin: 0 auto;
  position: relative;
  display: block;
  overflow: hidden;
  width: 95%;
  font-size: 0.9em;
  color: $gray-500;

  &::before {
    left: 0;
    margin-left: -1em;
  }
  &::after {
    right: 0;
    margin-right: -1em;
  }
  &::before, &::after {
    display: block;
    height: 0;
    padding: 0;
    border: none;
    border-bottom: 1px solid $gray-700;
    overflow: hidden;
    content: "";
    position: absolute;
    top: 50%;
    width: 50%;
  }
}

.upload-input{
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  z-index: -1;
}

.warning-section{
  &.true {
    height: auto;
    visibility: visible;
    opacity: 1;
    transition: opacity 1s linear, height 1s linear;
  }
  &.false {
    overflow: hidden;
    height: 0;
    visibility: hidden;
    opacity: 0;
    transition: visibility 1s, opacity 1s linear, height 1s linear;
  }
}