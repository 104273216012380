@import "../variables";
.table{
  --bs-table-bg: transparent;
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: $dark-btn-background;
  --bs-table-striped-bg: rgba(0, 0, 0, 0.05);
  --bs-table-active-color: #232223;
  --bs-table-active-bg: rgba(0, 0, 0, 0.1);
  --bs-table-hover-color: #232223;
  --bs-table-hover-bg: rgba(0, 0, 0, 0.075);
  width: 100%;
  margin-bottom: 1rem;
  color: var($--theme-text-color);
  vertical-align: top;
  border-color: $separator-color;
  tr:last-child{
    border-color: transparent;
  }

  &.table-hover tbody {
    tr:not(.selected):hover td {
      color: $theme-color-1 !important;
    }
  }

  > :not(caption) > * > * {
    color: var($--theme-text-color) !important;
  }
}

tbody {
  tr.selected{
    background-color: $theme-color-4;
    &:hover td { color: $button-text-color;}
  }
  td {
    vertical-align: middle;
  }
}

.large-icon {
  font-size: 34px;
}

.table-list{
  font-size: 0.95em;
  tbody {
    tr:nth-child(even) {
      //color: #CCC
    }
    tr:nth-child(odd) {
      color: #FFF;
      background-color: $separator-color-light;
    }
  }
}

.table-description{
  font-size: 0.95em;
  tbody {
    tr{
      td{
        color: $primary-color;
        &:first-child{

        }
        &:last-child{
          font-weight: bold;
        }

      }
    }
  }
}

.table-indicator {
  position: absolute;
  left: 50%;
  height: 15px;
  width: 15px;
  border-radius: 10px;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}