@import "../variables";
.accordion {
  border: none;
}

.accordion__item + .accordion__item {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.accordion__heading {
  background: $background-color;
}

.accordion__button {
  background-color: transparent;
  cursor: pointer;
  padding: 10px;
  width: 100%;
  text-align: left;
  border: 1px solid $separator-color;
  font-family: $font-alt;
  font-weight: 300;
  letter-spacing: 0.05em;
  font-size: 1.1em;

  &[aria-expanded="true"]{
    background-color: $separator-color;
  }

}

.accordion__button:hover {
  //background-color: $separator-color;
  color: $primary;
  border: 1px solid $primary;
}

.accordion__button:before {
  display: inline-block;
  content: '';
  height: 10px;
  width: 10px;
  margin-right: 12px;
  border-bottom: 2px solid currentColor;
  border-right: 2px solid currentColor;
  transform: rotate(-45deg);
  color: $primary;
}

.accordion__button[aria-expanded='true']::before,
.accordion__button[aria-selected='true']::before {
  transform: rotate(45deg);
}

[hidden] {
  display: none;
}

.accordion__panel {
  padding: 20px;
  border: 1px solid $separator-color;
  animation: fadein 0.35s ease-in;
}

/* -------------------------------------------------- */
/* ---------------- Animation part ------------------ */
/* -------------------------------------------------- */

@keyframes fadein {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.accordion-minimal{

  .accordion__item + .accordion__item {
    border-top: none;
  }

  .accordion__button {
    background-color: transparent;
    color: $primary;
    cursor: pointer;
    padding: 10px;
    width: 100%;
    text-align: center;
    border: none;
    font-size: 0.9em;
    font-family: $font-family-base;
    font-weight: normal;
    letter-spacing: 0;

    &[aria-expanded="true"]{
      background-color: $separator-color;
    }

  }

  .accordion__button:hover {
    //background-color: $separator-color;
    color: $primary-color;
    border: none;
  }

  .accordion__button:before{
    display: none;
  }
  .accordion__button:after {
    display: inline-block;
    content: '';
    height: 10px;
    width: 10px;
    margin-left: 12px;
    border-bottom: 2px solid currentColor;
    border-right: 2px solid currentColor;
    transform: rotate(-45deg);
    color: $primary;
  }
  .accordion__button:hover:after {
    color: $primary-color;
  }

  .accordion__button[aria-expanded='true']::after,
  .accordion__button[aria-selected='true']::after {
    transform: rotate(45deg);
  }

  [hidden] {
    display: none;
  }

  .accordion__panel {
    padding: 20px 0;
    border: none;
    animation: fadein 0.35s ease-in;
  }
}