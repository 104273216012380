@import "../variables";
.btn-check:checked + .btn-outline-primary, .btn-check:active + .btn-outline-primary, .btn-outline-primary:active, .btn-outline-primary.active, .btn-outline-primary.dropdown-toggle.show {
  //color: #fff;
  background-color: var($--theme-primary-alt) !important;
  border-color: var($--theme-primary) !important;
  &:hover{
    background-color: var($--theme-primary-alt) !important;
  }
}
.btn-primary, .btn-outline-primary {
  &:hover{
    background-color: var($--theme-primary-alt) !important;
    //color: var($--theme-text-color);
    border-color: var($--theme-primary) !important;
  }
}

